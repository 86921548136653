import './CreatePopUp.css'
import React, {useEffect, useState } from "react";
import { connect } from 'react-redux';
import DatePickerRange from '../DatePickerRange'
import DatePickerSingle from '../DatePickerSingle'
import {putUpdateTrackingRecord} from '../../services/httpApiTracking'
import Loading from '../Loading'
//svg
import WhiteCloseSvg from '../../svgs/WhiteCloseSvg'
import InputCalendarSvg from '../../svgs/InputCalendarSvg'
//action
import { formatEndDay, formatStartDay } from '../../actions/actionsDateTime';
function EditPopUp(props){
    const {remarks,equipmentId, category, maintenanceDate, projectId, certificateStartDate ,certificateEndDate, _id} = props.data
    const [formData, setFormData] = useState({equipmentId:equipmentId,category:category,projectId:projectId})
    const [certDateToggle, setCertDateToggle] = useState(false)
    const [certRange, setCertRange] = useState({
        display:{from:new Date(certificateStartDate).toLocaleDateString(),to:new Date(certificateEndDate).toLocaleDateString()},
        range:{from:new Date(certificateStartDate),to:new Date(certificateEndDate)}
    });
    const [maintDateToggle,  setMaintDateToggle] = useState(false)
    const [maintDate, setmaintDate] = useState({
        display:new Date(maintenanceDate).toLocaleDateString(),
        selected:new Date(maintenanceDate)
    })

    const [file, setFile] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    async function handlePostData(e){
        e.preventDefault()
        setIsLoading(true)
        const postBody = {...formData}
        if( certRange?.range?.from  && certRange?.range?.to && certRange?.range?.from?.toString()!=='Invalid Date' && certRange?.range?.to?.toString()!=='Invalid Date'){
            postBody.certificateStartDate = formatStartDay(certRange.range.from)
            postBody.certificateEndDate = formatEndDay(certRange.range.to)
            // console.log('using new date range')
        }else{
            //postBody.certificateStartDate = certificateStartDate
            //postBody.certificateEndDate = certificateEndDate
            // console.log('using old date Range')
        }
        if(maintDate?.selected && maintDate?.selected?.toString()!=='Invalid Date'){
            postBody.maintenanceDate = formatStartDay(maintDate.selected)
            // console.log('using new maintDate')
        }else{
            //postBody.maintenanceDate = maintenanceDate

            // console.log('using Old maintData')
        }
    
        // console.log(certRange)
        // console.log(postBody)
        // send post request to create new document "2024-04-25T03:30:35.839Z"
        const docRes = await putUpdateTrackingRecord(props.token.token,_id,postBody)
        // console.log(docRes)
        if(docRes.errno){
            setIsLoading(false)
            alert('Error updating documents')
            console.log('Error updating Documents')
        }
        // console.log(docRes)
        props.closePopUp(false)
        setIsLoading(false)
        props.fetchData()
    }
    function changeFormData(key, e){
        if(key === 'file') return setFile(e.target.files[0])
        setFormData({...formData, [key]:e.target.value})
    }
    function rangeSetter(any){
        setCertRange(any)
    }
    function maintDateSetter(any){
        setmaintDate(any)
    }
    return (
        <div className="w-[25%] min-w-[460px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
            {isLoading && <Loading />}
            <div className="flex flex-row justify-between mb-8">
                <div className="text-[18px] font-bold">Edit Device/Item</div>
                <WhiteCloseSvg className='cursor-pointer hover:scale-110' onClick={() => {props.closePopUp(false)}}/>
            </div>
            <form onSubmit={handlePostData} className="flex flex-col">
                {/* <div className="flex flex-col mb-4">
                    <label htmlFor="projectId">ProjectId*</label>
                    <input type="text" id="projectId" defaultValue={projectId} name="projectId" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('projectId', e)}}/>
                </div> */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="category">Category*</label>
                    <input type="text" id="category" defaultValue={category} name="category" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('category', e)}}/>
                </div>
                <div className="flex flex-col mb-4">
                    <label htmlFor="equipmentId">Equipment ID*</label>
                    <input type="text" id="equipmentId" defaultValue={equipmentId} name="equipmentId" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('equipmentId', e)}}/>
                </div>
                <div className="flex flex-col mb-4 relative">
                    <label htmlFor="certificateDateRange">Certificate Date*</label>
                    <div className='prevent-select w-full h-[40px] text-white flex flex-row items-center px-2 rounded-full bg-transparent h-[42px] mt-[8px] px-4 cursor-pointer hover:scale-105' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onClick={() => setCertDateToggle(!certDateToggle)}>
                        <InputCalendarSvg className='mr-2' />{certRange?.display ? certRange?.display.from+ ' To '  + certRange?.display.to : 'Click to update date'}
                    </div>
                    {certDateToggle && <div style={{zIndex:99}}>
                        <div className='fixed inset-0 bg-[#00000080]' onClick={()=>{setCertDateToggle(false)}}/>
                        <DatePickerRange setter={rangeSetter} from={certificateStartDate} to={certificateEndDate}/>
                    </div>}
                </div>
                <div className="flex flex-col mb-4 relative">
                    <label htmlFor="certificateDateRange">Checking & Maintainance*</label>
                    <div className='prevent-select w-full h-[40px] text-white flex flex-row items-center px-2 rounded-full bg-transparent h-[42px] mt-[8px] px-4 cursor-pointer hover:scale-105' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onClick={() => setMaintDateToggle(!maintDateToggle)}>
                    <InputCalendarSvg className='mr-2' /> {maintDate?.display ? maintDate.display : 'Click to update date'}
                    </div>
                    {maintDateToggle && <div style={{zIndex:99}}>
                        <div className='fixed inset-0 bg-[#00000080]' onClick={()=>{setMaintDateToggle(false)}}/>
                        <DatePickerSingle setter={maintDateSetter} style={{top: -370, zIndex:99}}/>
                    </div>}
                </div>
                <div className="flex flex-col mb-6">
                    <label htmlFor="remarks">Remarks*</label>
                    <input type="text" id="remarks" defaultValue={remarks} name="remarks" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('remarks', e)}}/>
                </div>
                {/* <div className="flex flex-col mb-6">
                    <label htmlFor="file" className='flex flex-col'>
                        <p>File Attachment (0)</p>
                        <p className='w-full py-[8px] mt-[8px] text-white text-center rounded-full cursor-pointer hover:scale-105' style={{borderWidth:1, borderColor:'#FFFFFF80'}}>Upload File</p>
                    </label>
                    <input type="file" id="file" name="file" onChange={(e) => {changeFormData('file', e)}} value={formData.name} className='w-full' style={{display:'none'}}/>
                </div> */}
                <div className="flex flex-col mb-4">
                    <input type="submit" className='bg-[#00202A] text-[#63BDFF] border-none h-[40px] rounded-full cursor-pointer hover:scale-105' id="submitBtn" name="submit" value='Update'/>
                </div>
            </form>
        </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(EditPopUp)