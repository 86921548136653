import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import {COOKIESPREFIX} from '../config.js'
import { connect } from 'react-redux';
import {loginWithPassword} from '../services/request.service'
import { Login } from '../actions/actions'
import './Login.css';

const cred_max_length=60//todo update this number later
var CapsLock=function(){var e=/Mac/.test(navigator.platform),b=!1,d=[];window.addEventListener("keypress",function(a){var c=a.charCode;a=a.shiftKey;var f=b;97<=c&&122>=c?b=a:!(65<=c&&90>=c)||a&&e||(b=!a);b!==f&&d.forEach(function(a){return a(b)})});return{isOn:function(){return b},addListener:function(a){d.push(a)}}}();


function isValidEmail(mail) {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
    return (true)
  }
  return (false)
}


function Component(props) {
  const [userInput, setUserInput] = useState({})
  const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_cred`,`@${COOKIESPREFIX}_token`, `@${COOKIESPREFIX}_auto`]);
  const [errMessage, setErrMessage] = useState(null)
  const [autoUser, autoPass] = (cookies[`@${COOKIESPREFIX}_auto`]?.split('_') || [null, null])

  useEffect(() => {
    if(autoUser && autoPass){
      setUserInput({email:autoUser, password:autoPass})
    }
  },[])
  async function loginHandler(e){
    e.preventDefault()
    //input check
    const {email:username, password} = userInput
    if(!username || !isValidEmail(username)){
      return setErrMessage('Invalid email')
    }
    if(!password || password.length<5||password===''||password.length>cred_max_length){
      return setErrMessage('Invalid password')
    }
    const res = await loginWithPassword(username, password)
    if(res.errno){
      return setErrMessage('Wrong credential')
    }else{
      const {token, type} = res.token 
      props.dispatch(Login(true, token, type, username))
      setCookie(`@${COOKIESPREFIX}_cred`, {username,password,usertype: type}, { path: '/' });
      setCookie(`@${COOKIESPREFIX}_token`, token, { path: '/', maxAge:604800}); //7 days
      setCookie(`@${COOKIESPREFIX}_auto`, `${username}_${password}`, { path: '/', maxAge:1209600}); //7 days
      setErrMessage(null)
    }
  }
  // function bypass(){
  //   props.dispatch(Login(true, 'token', 'type', 'username'))
  // }
  function updateInput(field, e){
    const update = {[field]: e.target.value}
    setUserInput({...userInput, ...update})
  }
  return (
    <div className='bg-[#0C0D14] text-white flex flex-row justify-center items-center w-[100vw] h-[100vh]'>
      <div className='p-[16px] max-w-[445px] flex-1' style={{borderColor:'#FFFFFF40', borderWidth:1, borderRadius:24}}>
          <div className='p-[32px]' style={{borderColor:'#919191', borderWidth:1, borderRadius:20}} >
            <div className='text-[32px] font-bold'>NovaLinx</div>
            <div className='text-[20px] font-bold mt-[72px] mb-[16px]'>Welcome Back!</div>
            <form onSubmit={loginHandler} className='text-black flex flex-1 flex-col gap-2'>
              <input value={userInput?.email||''} style={{backgroundColor: 'transparent', borderColor:'#FFFFFF80', borderWidth: 1}} className='rounded-full px-[16px] py-[8px] rounded flex-1 mb-[24px] text-white' name='email' placeholder='Email' onChange={(e) => updateInput('email', e)}></input>
              <input type='password' value={userInput?.password||''} style={{backgroundColor: 'transparent', borderColor:'#FFFFFF80', borderWidth: 1}} className='rounded-full px-[16px] py-[8px] rounded flex-1 text-white' name='password' placeholder='Password' onChange={(e) => updateInput('password', e)}></input>
              {errMessage && <div className='text-sm text-[red]'>{errMessage}</div>}
              <div className='text-white underline text-[14px] mt-[16px] cursor-pointer'>Forget Password</div>
              <button type='submit' className='text-white mt-[12px] py-[9px] rounded mt-[40px] bg-[#00202A] rounded-full cursor-pointer'>Login</button>
              <button type='' className='text-white border border-[#FFFFFF40] my-[12px] py-[9px] rounded rounded-full cursor-pointer'>Sign Up</button>
            </form>
          </div>
      </div>
      {/* <button onClick={bypass} className='border-2 px-2 my-2'>bypass</button> */}
    </div>
  );
}

export default connect((store)=>{
    return{
      token:store.tokenReducer,
    }
  })(Component)
  